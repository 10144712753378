#bien_localisation,
#page_right,
#recherche_map {

	.leaflet-marker-pane img {
		width: 63px !important;
		height: 58px !important;
		margin-top: -58px !important;
		margin-left: 0 !important;
	}
}
// Import setup
@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";
@import "setup/mixins.scss";
@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/grid.scss";
@import "setup/cssowl.scss";
@import "setup/sprites/global.scss";
@import "setup/sprites/picto.scss";
@import "setup/sprites/share.scss";

// Import componants 
@import "componants/animate.scss";
@import "componants/dialog.scss";
@import "componants/flexslider.scss";
@import "componants/font-awesome/font-awesome.scss";

// Import elements
@import "elements/button.scss";
@import "elements/rte.scss";
@import "elements/messages.scss";
@import "elements/dialog.scss";
@import "elements/gradient.scss";
@import "elements/button.scss";
@import "elements/hamburger.scss";
@import "elements/pager.scss";
@import "elements/skip_nav.scss";
@import "elements/titles.scss";
@import "elements/datepicker.scss";
@import "elements/breadcrumb.scss";
@import "elements/maps.scss";
@import "elements/back_to_top.scss";
@import "elements/rangeSlider.scss";

// Import main files
@import "global.scss";
@import "header.scss";
@import "footer.scss";
@import "print.scss";

@import "forms.scss";
// @import "home.scss";
@import "page.scss";
// @import "forms.scss";



/* BLOCKS */
@import "blocks/social_menu.scss";
// @import "blocks/autre_recherche.scss";
// @import "blocks/node_banner.scss";


/* LISTS */
// @import "lists/liste_appartements_home.scss";
// @import "lists/liste_appartements_page.scss";
// @import "lists/listes_ronds.scss";
// @import "lists/liste_biens.scss";
// @import "lists/liste_equipements.scss";
// @import "lists/liste_pieces.scss";
// @import "lists/liste_prix_bien.scss";
// @import "lists/liste_calendriers.scss";
// @import "lists/liste_faq.scss";
// @import "lists/liste_rencontrer.scss";
// @import "lists/liste_liens_home.scss";
// @import "lists/liste_selection.scss";
// @import "lists/liste_services.scss";


/* NODES */
// @import "nodes/bien.scss";
// @import "nodes/locataires-proprietaires.scss";
// @import "nodes/locations-luxe.scss";
// @import "nodes/webform.scss";
@import "nodes/nodes.scss";


/* PAGES */
// @import "pages/page_404.scss";
// @import "pages/page_selection.scss";
// @import "pages/page_confirmation.scss";
// @import "pages/page_recherche.scss";
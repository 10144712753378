.rte {
		
	strong,
	b {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}

	u {
		text-decoration: underline;
	}
	
	p {
		font-size: 16px;
		color: $greyRTE;
		line-height: 30px;

		@include breakpoint(small down) {
			font-size: 12px;
		}

		a {
			color: $blue;
			text-decoration: underline;

			&:hover {
				color: $gold;
			}
		}

		&:not(.nomargin):not(:last-child) {
			margin-bottom: 30px;

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}
	}

	.or {
		color: $gold;
		font-size: 14px;

		@include breakpoint(small down) {
			font-size: 12px;
			line-height: 20px;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	h2,
	h3,
	h4,
	h5 {
		color: $gold;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 30px;

		@include breakpoint(small down) {
			margin-bottom: 20px;
		}
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	h4 {
		font-size: 16px;
	}

	h5 {
		font-size: 14px;
	}

	> ul {

		&:not(:last-child) {
			margin-bottom: 30px;

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}

		> li {
			font-size: 16px;
			color: $greyRTE;
			line-height: 30px;
			padding-left: 65px;
			position: relative;
			background: url(../img/icon_validate.png) left 30px top 8px no-repeat;
			background-size: 15px auto;

			@include breakpoint(small down) {
				font-size: 12px;
				background-position: left 10px top 8px;
				padding-left: 45px;
			}
			
			strong {
				font-weight: 700;
			}
		}
	}

	.asterisque {
		font-size: 14px;

		@include breakpoint(small down) {
			font-size: 12px;
		}
	}

	blockquote {
		
	}
}

.rte_page_base {

	p,
	ul li {
		font-size: 16px;
		color: $black;
		line-height: 30px;

		@include breakpoint(small down) {
			font-size: 12px;
			line-height: 20px;
		}
		
		strong {
			font-weight: 700;
		}
	}

	a {
		color: $gold;

		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		list-style: circle;
		padding-left: 20px;
	}

	h2 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 15px;
		color: $blue;

		@include breakpoint(small down) {
			font-size: 16px;
		}
	}

	h3 {
		font-size: 18px;
		font-weight: 700;
		color: $gold;
		margin-bottom: 10px;

		@include breakpoint(small down) {
			font-size: 14px;
		}
	}
}
.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.rangeslider {
  background: $gold;
  position: relative;
}

.rangeslider--horizontal {
  height: 5px;
  width: 100%;
}

.rangeslider--vertical {
  width: 5px;
  min-height: 150px;
  max-height: 100%;
}

.rangeslider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeslider__fill {
  background: $didYouReallyNeedThisDifferentGrey;
  position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}

.rangeslider__handle {
  cursor: pointer;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  background: url(../img/range_handle.png) center no-repeat;
  background-size: 26px auto;
}
.rangeslider--horizontal .rangeslider__handle {
  top: -10px;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}
.rangeslider--vertical .rangeslider__handle {
  left: -10px;
  touch-action: pan-x;
  -ms-touch-action: pan-x;
}

input[type="range"]:focus + .rangeslider .rangeslider__handle {
  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}

/* Date Picker Default Styles */
.ui-datepicker {
	padding: 0;
	border: 0;
	border-radius: 0 0 5px 5px;
    overflow: hidden;
}
.ui-datepicker * {
	padding: 0;
}
.ui-datepicker table {
	font-size: 13px;
	margin: 0;
	width: 100%;
    overflow: hidden;
}
.ui-datepicker .ui-datepicker-header {
	border: none;
	background: $blue;
	color: #fff;
	font-weight: normal;
}
.ui-datepicker .ui-datepicker-header .ui-state-hover {
	background: $blue;
	border-color: transparent;
	cursor: pointer;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.ui-datepicker thead {
	background: $white;
	color: #fff;
}
.ui-datepicker .ui-datepicker-title {
	margin-top: .4em;
	margin-bottom: .3em;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
	height: 1em;
	top: 12px;
	border: none;
}
.ui-datepicker .ui-datepicker-prev-hover {
	left: 2px;
}
.ui-datepicker .ui-datepicker-next-hover {
	right: 2px;
}
.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
	background: url(../img/chevron_calendar.png) center no-repeat;
	margin-top: 0;
	top: 0;
	font-weight: normal;
	font-size: 0;
	width: 11px;
	height: 19px;
}
.ui-datepicker .ui-datepicker-prev span {
	@include rotate(180deg);
}
.ui-datepicker th {
	padding: 0.75em 0;
	color: $wowAnotherGreyText;
	font-weight: normal;
	border: none;
	font-size: 12px;
}
.ui-datepicker td {
	background: $white;
	border: none;
	padding: 0;
	height: 38px;
	width: 38px;
	vertical-align: middle;

	&:not(.ui-datepicker-other-month) {
		border: 1px solid $lightGreyBorder;
	}

	a {
		display: block;
	}
}
.ui-datepicker td .ui-state-default {
	background: transparent;
	border: none;
	text-align: center;
	padding: .5em;
	margin: 0;
	font-weight: normal;
	color: $lightBlack;
	font-size: 14px;
}
.ui-datepicker td .ui-state-active,
.ui-datepicker td .ui-state-hover {
	color: $blue;
	font-weight: 700;
}
.ui-datepicker td.ui-state-disabled,
.ui-datepicker td.ui-state-disabled .ui-state-default {
	opacity: 1;
	color: $lightGreyText;
}
/* Other Datepicker Color Schemes */
/* Blue */
.admin-color-blue .ui-datepicker .ui-datepicker-header,
.admin-color-blue .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-blue .ui-datepicker thead {
	background: $white;
}
.admin-color-blue .ui-datepicker th {
	border-color: #52accc;
}
.admin-color-blue .ui-datepicker td .ui-state-active,
.admin-color-blue .ui-datepicker td .ui-state-hover {
	background: #096484;
}
/* Coffee */
.admin-color-coffee .ui-datepicker .ui-datepicker-header,
.admin-color-coffee .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-coffee .ui-datepicker thead {
	background: #46403c;
}
.admin-color-coffee .ui-datepicker th {
	border-color: #59524c;
}
.admin-color-coffee .ui-datepicker td .ui-state-active,
.admin-color-coffee .ui-datepicker td .ui-state-hover {
	background: #c7a589;
}
/* Ectoplasm */
.admin-color-ectoplasm .ui-datepicker .ui-datepicker-header,
.admin-color-ectoplasm .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-ectoplasm .ui-datepicker thead {
	background: #413256;
}
.admin-color-ectoplasm .ui-datepicker th {
	border-color: #523f6d;
}
.admin-color-ectoplasm .ui-datepicker td .ui-state-active,
.admin-color-ectoplasm .ui-datepicker td .ui-state-hover {
	background: #a3b745;
}
/* Midnight */
.admin-color-midnight .ui-datepicker .ui-datepicker-header,
.admin-color-midnight .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-midnight .ui-datepicker thead {
	background: #26292c;
}
.admin-color-midnight .ui-datepicker th {
	border-color: #363b3f;
}
.admin-color-midnight .ui-datepicker td .ui-state-active,
.admin-color-midnight .ui-datepicker td .ui-state-hover {
	background: #e14d43;
}
/* Ocean */
.admin-color-ocean .ui-datepicker .ui-datepicker-header,
.admin-color-ocean .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-ocean .ui-datepicker thead {
	background: #627c83;
}
.admin-color-ocean .ui-datepicker th {
	border-color: #738e96;
}
.admin-color-ocean .ui-datepicker td .ui-state-active,
.admin-color-ocean .ui-datepicker td .ui-state-hover {
	background: #9ebaa0;
}
/* Sunrise */
.admin-color-sunrise .ui-datepicker .ui-datepicker-header,
.admin-color-sunrise .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-sunrise .ui-datepicker thead {
	background: #be3631;
}
.admin-color-sunrise .ui-datepicker th {
	border-color: #cf4944;
}
.admin-color-sunrise .ui-datepicker td .ui-state-active,
.admin-color-sunrise .ui-datepicker td .ui-state-hover {
	background: #dd823b;
}
/* Light */
.admin-color-light .ui-datepicker .ui-datepicker-header,
.admin-color-light .ui-datepicker .ui-datepicker-header .ui-state-hover,
.admin-color-light .ui-datepicker thead {
	background: #e5e5e5;
}
.admin-color-light .ui-datepicker td {
	background: #fff;
}
.admin-color-light .ui-datepicker .ui-datepicker-next span,
.admin-color-light .ui-datepicker .ui-datepicker-prev span {

}
.admin-color-light .ui-datepicker th {
	border-color: #fff;
}
.admin-color-light .ui-datepicker .ui-datepicker-title,
.admin-color-light .ui-datepicker td .ui-state-default,
.admin-color-light .ui-datepicker th {
	color: #555;
}
.admin-color-light .ui-datepicker td .ui-state-active,
.admin-color-light .ui-datepicker td .ui-state-hover {
	color: #fff;
	background: #888;
}
.admin-color-light .ui-datepicker td.ui-state-disabled,
.admin-color-light .ui-datepicker td.ui-state-disabled .ui-state-default {
	color: #ccc;
}

.ui-corner-all:not(.ui-datepicker) {
	border-radius: 0;
}

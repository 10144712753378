#admin-menu {
	z-index: 1002;
}

#header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1001;
	background: $blue;
	text-align: right;
	font-size: 0;
	line-height: 92px;
	@include clearfix();

	@include breakpoint(small down) {
		line-height: 1;
		padding: 15px 0;
		text-align: center;
	}

	#logo {
		float: left;

		@include breakpoint(small down) {
			float: none;
			margin-bottom: 15px;
		}


		img {
			width: 354px;
			height: auto;
			vertical-align: middle;

			@include breakpoint(large down) {
				width: 177px;
			}
		}
	}

	#main_menu,
	#header_selection,
	#language_switcher {
		display: inline-block;
		vertical-align: middle;
	}

	#main_menu {
		margin-right: 10px;

		@include breakpoint(medium down) {
			position: fixed;
			left: -250px;
			top: 92px;
			width: 250px;
			height: calc(100vh - 92px);
			background: $blue;
			text-align: left;
			padding: 0 15px;
			line-height: 1;
			@include transition(0.4s);

			&.toggled {
				left: 0;
			}
		}

		li {
			display: inline-block;
			vertical-align: middle;

			@include breakpoint(medium down) {
				display: block;
			}

			&:not(:last-child) {
				margin-right: 15px;

				@include breakpoint(1300px down) {
					margin-right: 5px;
				}
			}

			a {
				display: block;
				color: $white;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				height: 52px;
				line-height: 52px;
				border-radius: 5px;
				padding: 0 15px;

				&:hover,
				&.active {
					background: $white;
					color: $gold;
				}

				@include breakpoint(large down) {
					padding: 0 10px;
				}

				@include breakpoint(medium down) {
					display: inline-block;
				}
			}
		}
	}

	#burger {
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;

		@include breakpoint(large up) {
			display: none;
		}

		@include breakpoint(small down) {
			float: left;
			margin-top: 13px;
		}
	}

	#header_selection {
		color: $white;
		height: 52px;
		line-height: 52px;
		border-radius: 5px 50px 50px 5px;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		width: 195px;
		padding-left: 48px;
		text-align: left;
		position: relative;
		z-index: 1;
		margin-right: 30px;
		overflow: hidden;

		@include breakpoint(large down) {
			margin-right: 15px;
		}

		@include breakpoint(small down) {
			padding-left: 32px;
			font-size: 10px;
			width: 155px;
			height: 30px;
			line-height: 30px;
			margin-right: 0;
			border-radius: 50px;
		}

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15px;
			width: 18px;
			@include transition(0.3s);

			@include breakpoint(small down) {
				left: 7px;
			}
		}

		&:before {
			background: url(../img/icon_key.png) center no-repeat;
			background-size: 18px auto;

			@include breakpoint(small down) {
				background-size: 14px auto;
			}
		}

		&:after {
			background: url(../img/icon_key_hover.png) center no-repeat;
			background-size: 18px auto;
			opacity: 0;

			@include breakpoint(small down) {
				background-size: 14px auto;
			}
		}

		.gradient {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			@include goldGradient();
			@include transition(0.3s);
		}

		.count {
			float: right;
			font-size: 18px;
			padding-right: 20px;

			@include breakpoint(small down) {
				font-size: 14px;
			}
		}

		&:hover {
			background: $white;
			color: $gold;

			&:before,
			.gradient {
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}
	}

	#language_switcher {

		@include breakpoint(small down) {
			float: right;
			margin-top: 7px;
		}

		li {
			display: inline-block;
			vertical-align: middle;

			&:not(:last-child) {
				margin-right: 15px;

				@include breakpoint(small down) {
					margin-right: 10px;
				}
			}

			a {
				display: block;
				width: 21px;
				height: 15px;

				&:not(.active) {
					opacity: 0.5;

					&:hover {
						opacity: 1;
					}
				}
			}

			&.fr a {
				background: url(../img/flag_fr.png) center no-repeat;
				background-size: 100%;
			}

			&.en a {
				background: url(../img/flag_en.png) center no-repeat;
				background-size: 100%;
			}
		}
	}
}

body.admin-menu #header {
	top: 29px;
}





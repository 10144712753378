#footer {
	background: $blue;
	padding-top: 55px;
	padding-bottom: 35px;
	text-align: center;

	@include breakpoint(small down) {
		padding: 20px 0;
	}

	.footer_top {
		margin-bottom: 35px;

		@include breakpoint(small down) {
			margin-bottom: 25px;
		}

		h3 {
			color: $gold;
			text-transform: uppercase;
			font-size: 21px;
			margin-bottom: 30px;
			line-height: 25px;

			@include breakpoint(small down) {
				font-size: 16px;
				margin-bottom: 15px;
			}
		}

		p {
			color: $white;
			font-size: 14px;
			line-height: 30px;

			@include breakpoint(small down) {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}

	.social_menu {
		margin-bottom: 55px;

		@include breakpoint(small down) {
			margin-bottom: 25px;
		}
	}

	.boutons {
		margin-bottom: 50px;

		@include breakpoint(small down) {
			margin-bottom: 15px;
		}

		a {

			&:not(:last-child) {
				margin-right: 35px;

				@include breakpoint(medium down) {
					margin-right: 0;
					margin-bottom: 20px;
				}

				@include breakpoint(small down) {
					margin-bottom: 15px;
				}
			}

			&.rechercher {
				@include goldButton();
				padding: 15px 35px;

				@include breakpoint(medium down) {
					display: block;
				}
			}

			&.louer {
				@include goldBorderButton();
				padding: 14px 35px;

				@include breakpoint(medium down) {
					display: block;
				}
			}
		}
	}

	#footer_bottom {
		@include clearfix();

		.left {
			float: left;
			width: 50%;
			text-align: right;

			@include breakpoint(small down) {
				float: none;
				width: 100%;
				text-align: center;
			}

			p {
				color: $gold;
				font-size: 10px;
				text-transform: uppercase;
				line-height: 22px;
			}
		}

		.right {
			float: right;
			width: 50%;
			text-align: left;

			@include breakpoint(small down) {
				float: none;
				width: 100%;
				text-align: center;
				margin-bottom: 15px;
			}

			li {
				display: inline-block;
				vertical-align: middle;
				margin-left: 25px;

				@include breakpoint(small down) {

					&:first-child {
						margin-left: 0;
					}
				}

				a {
					color: $white;
					font-size: 12px;
					text-transform: uppercase;
					line-height: 20px;

					@include breakpoint(small down) {
						font-size: 10px;
					}

					&:hover {
						color: $gold;
					}
				}
			}
		}
	}
}
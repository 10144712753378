form {

	input,
	select,
	textarea {
		appearance: none;
		outline: none;
		resize: none;
		border: 0;
		box-shadow: none;
		border-radius: 0;
		background: $white;
		padding: 0;
		margin: 0;
		font-family: $ffP;	
	}

	input[type=radio],
	input[type=checkbox] {
		height: 18px !important;
		width: 0.1px !important;
		opacity: 0 !important;
		padding: 0 !important;
		position: absolute;

		&+ label {
			padding-left: 25px;
			font-size: 14px;
			color: $greyCheckbox;
			line-height: 20px;
			position: relative;
			cursor: pointer;
			@include transition(0.3s);

			@include breakpoint(small down) {
				font-size: 12px;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 3px;
				width: 13px;
				height: 13px;
				border-radius: 50%;
				border: 1px solid $greyCheckbox;
				background: $white;
				@include transition(0.3s);
			}
		}

		&:checked + label {
			color: $blue;
			//font-weight: 700;

			&:before {
				background: $blue;
				border-color: $blue;
			}
		}
	}

	select {
		cursor: pointer;
	}

	input[type=submit] {
		cursor: pointer;
		@include transition(0.3s);
	}

	&#home_search {

		input[type=text],
		select,
		.form_label {
			height: 90px;
			font-size: 14px;
			font-weight: 700;
			color: $blue;
			padding-left: 80px;
			padding-right: 15px;

	   		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: $blue;
			  opacity: 1;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: $blue;
			  opacity: 1;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: $blue;
			  opacity: 1;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: $blue;
			  opacity: 1;
			}

			@include breakpoint(large down) {
				padding-left: 50px;
			}

			@include breakpoint(medium down) {
				height: 60px;
			}

			@include breakpoint(small down) {
				font-size: 12px;
				padding-left: 40px;
				padding-right: 10px;
				height: 45px;
			}
		}

		.form_label {
			background: $white;
			line-height: 90px;
			cursor: pointer;

			@include breakpoint(medium down) {
				line-height: 60px;
			}

			@include breakpoint(small down) {
				line-height: 45px;
			}
		}

		.form_item {
			float: left;

			&:not(:last-child):not(:nth-last-child(2)) {
				border-right: 1px solid $greyBorder;
			}

			&.date_arrivee {
				width: 218px;
			}

			&.date_depart {
				width: 226px;
			}

			&.type {
				width: 209px;
			}

			&.localisation {
				width: 200px;
			}

			&.budget {
				width: 174px;
			}

			&.submit {
				width: 90px;
			}

			@include breakpoint(large up) {

				&:first-child input {
					border-radius: 5px 0 0 5px;
				}

				&:last-child input {
					border-radius: 0 5px 5px 0;
				}
			}

			@include breakpoint(large down) {

				&.date_arrivee,
				&.date_depart,
				&.type,
				&.localisation,
				&.budget {
					width: calc((100% - 90px) / 5);
				}
			}

			@include breakpoint(medium down) {

				&.date_arrivee,
				&.date_depart,
				&.type,
				&.localisation,
				&.budget,
				&.submit {
					width: calc(100% / 3);
				}

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					border-bottom: 1px solid $greyBorder;
				}

				&:first-child input {
					border-radius: 5px 0 0 0;
				}

				&:nth-child(3) {
					border-right: 0 !important;

					select {
						border-radius: 0 5px 0 0;
					}
				}

				&:nth-child(4) input {
					border-radius: 0 0 0 5px;
				}

				&:last-child input {
					border-radius: 0 0 5px 0;
				}
			}

			@include breakpoint(small down) {

				&.date_arrivee,
				&.date_depart,
				&.type,
				&.localisation,
				&.budget,
				&.submit {
					width: 50%;
				}

				&:nth-child(2) {
					border-right: 0 !important;

					select {
						border-radius: 0 5px 0 0;
					}
				}

				&:nth-child(3) {
					border-right: 1px solid $greyBorder !important;


					select {
						border-radius: 0;
					}
				}

				&:nth-child(4) {
					border-bottom: 1px solid $greyBorder;

					input {
						border-radius: 0;
						border-right: 0 !important;
					}
				}

				&:nth-child(5) input {
					border-radius: 0 0 0 5px;
				}

				&:last-child input {
					border-radius: 0 0 5px 0;
				}
			}


			input,
			select{
				width: 100%;
			}

			&.date_arrivee input,
			&.date_depart input {
				background: url(../img/icon_calendar.png) left 35px center no-repeat $white;
				background-size: 22px auto;

				@include breakpoint(large down) {
					background-position: left 10px center;
				}
			}

			&.type .form_label {
				background: url(../img/icon_house.png) left 35px center no-repeat $white;
				background-size: 21px auto;

				@include breakpoint(large down) {
					background-position: left 10px center;
				}
			}

			&.localisation .form_label {
				background: url(../img/icon_pin.png) left 35px center no-repeat $white;
				background-size: 17px auto;

				@include breakpoint(large down) {
					background-position: left 10px center;
				}
			}

			&.budget input {
				background: url(../img/icon_pig.png) left 35px center no-repeat $white;
				background-size: 26px auto;

				@include breakpoint(large down) {
					background-position: left 10px center;
				}
			}

			&.type,
			&.localisation {
				position: relative;

				.radio_wrap {
					position: absolute;
					top: 90px;
					right: 0;
					min-width: 100%;
					max-width: calc(100vw - 21px);
					background: $white;
					padding: 15px 20px;
					border-top: 1px solid $greyBorder;
					@include transition(0.3s);
					opacity: 0;
					pointer-events: none;
					z-index: 2;

					@include breakpoint(medium down) {
						top: 60px;
					}

					@include breakpoint(medium only) {
						border: 1px solid $greyBorder;
					}

					@include breakpoint(small down) {
						top: 45px;
					}

					li {

						&:not(:last-child) {
							margin-bottom: 10px;
						}

						h3 {
							color: $gold;
							text-transform: uppercase;
							font-size: 16px;
							font-weight: 700;
							margin-bottom: 15px;
						}
					}

					&.two_cols {
						@include clearfix();
						width: 420px;

						> li {
							//float: left;
							//width: 50%;
							columns: 2;
						}
					}
				}

				.active + .radio_wrap {
					pointer-events: all;
					opacity: 1;
				}
			}

			&.localisation .radio_wrap {
				//height: 225px;
				height: 360px;
				overflow: auto;			

				@include breakpoint(medium only) {
					left: 0;
					right: inherit;
				}
			}

			&.submit {
				position: relative;

				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					pointer-events: none;
				}

				&:before {
					background: url(../img/icon_loupe.png) center no-repeat;
					background-size: 24px auto;
					z-index: 1;
				}

				&:after {
					left: -1px;
					border-radius: 0 5px 5px 0;
					background: url(../img/icon_loupe_hover.png) center no-repeat $white;
					background-size: 24px auto;
					z-index: 2;
					opacity: 0;
					border-left: 1px solid $greyBorder;
					@include transition(0.3s);
				}

				&:hover:after {
					opacity: 1;
				}

				input[type=submit] {
					height: 90px;
					font-size: 0;
					@include goldGradient();

					@include breakpoint(medium down) {
						height: 60px;
					}

					@include breakpoint(small down) {
						height: 45px;
					}
				}
			}

			@include breakpoint(medium down) {

			}
		}
	}

	&.webform-client-form {

		input,
		select,
		textarea {
			border: 1px solid $greyWebformBorder;
			border-radius: 5px;
			height: 50px;
			padding: 0 20px;
			font-size: 14px;
			width: 100%;
			color: $blue;
			font-weight: 500;

			@include breakpoint(small down) {
				height: 40px;
			}

	   		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  	color: $guessWhatItsAnotherGreyText;
			  	opacity: 1;		
			  	font-weight: 400;	  	
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  	color: $guessWhatItsAnotherGreyText;
			  	opacity: 1;			
			  	font-weight: 400;  	
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  	color: $guessWhatItsAnotherGreyText;
			  	opacity: 1;		
			  	font-weight: 400;	  	
			}
			&:-moz-placeholder { /* Firefox 18- */
			  	color: $guessWhatItsAnotherGreyText;
			  	opacity: 1;			
			  	font-weight: 400;  	
			}
		}

		textarea {
			height: 120px;
			padding-top: 20px;
		}

		select {
			padding-right: 50px;
			background: url(../img/chevron_blue.png) right 18px center no-repeat;
			background-size: 18px auto;
		}

		fieldset {

			legend {
				display: block;
				font-size: 18px;
				font-weight: 700;
				color: $gold;
				text-transform: uppercase;

				@include breakpoint(small down) {
					font-size: 14px;
				}
			}

			.fieldset-wrapper {
				@include row();
				padding-top: 30px;

				.fieldset-description {
					@include grid-column(6, $gutters);
					margin-bottom: 15px;
					color: $blue;
					font-size: 16px;
					line-height: 25px;
					
					@include breakpoint(medium down) {
						width: 100%;
						font-size: 12px;
						line-height: 20px;
					}
				}

				> .form-item {
					margin-bottom: 20px;					
				}
			}
		}

		.form-item {

			&:not(.date_half):not(.form-type-radio):not(.label):not(.label_inline) > label {
				display: none;
			}

			&.field_full,
			&.webform-component-markup {
				@include grid-column(12, $gutters);
			}

			&.field_three_quarters {
				@include grid-column(8, $gutters);
			}

			&.field_half {
				@include grid-column(6, $gutters);

				@include breakpoint(small down) {
					width: 100%;
				}
			}

			&.field_third {
				@include grid-column(4, $gutters);

				@include breakpoint(medium down) {
					width: 50%;
				}

				@include breakpoint(small down) {
					width: 100%;
				}
			}

			&.field_quarter {
				@include grid-column(3, $gutters);

				@include breakpoint(medium down) {
					width: 50%;
				}

				@include breakpoint(small down) {
					width: 100%;
				}
			}

			&.date_half {
				display: inline-block;
				vertical-align: top;

				@include breakpoint(xlarge up) {
					padding-left: 30px;
				}

				@include breakpoint(medium down) {
					padding-left: 10px;
					padding-right: 10px;
				}

				@include breakpoint(small down) {
					display: block;
					width: 100%;
				}

				@include breakpoint(medium up) {

					&:not(:last-child) {
						margin-right: 35px;
					}

					> label,
					.webform-container-inline {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}

			&.date,
			&.date_half {

				.form-type-select {
					height: 0.1px;
					width: 0.1px;
					overflow: hidden;
					position: absolute;
					opacity: 0;
				}

				> label {
					margin-right: 30px;
					color: $guessWhatItsAnotherGreyText;
					font-size: 14px;

					@include breakpoint(large only) {
						margin-right: 20px;
					}

					@include breakpoint(medium down) {
						width: 150px;
					}

					@include breakpoint(small down) {
						display: block;
						margin-bottom: 10px;
					}
				}

				&.date input[type=text] {
					width: 100%;
				}

				input[type=text] {
					padding-right: 60px;
					background: url(../img/icon_calendar2.png) right 16px center no-repeat;
					width: 232px;
					color: $blue;
					font-weight: 700;
					font-size: 14px;
					cursor: pointer;

					@include breakpoint(large only) {
						width: 200px;
					}

					@include breakpoint(small down) {
						display: block;
						width: 100%;
						background-size: 14.5px auto;
					}
				}
			}

			&.radio_columns {
				margin-top: 10px;

				@include breakpoint(medium down) {
					width: 100%;
				}

				> label {
					display: block;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 20px;
					color: $blue;
				}

				.form-radios {
					columns: 4;

					.form-item {
						margin-bottom: 10px;
					}

					@include breakpoint(small down) {
						columns: 3;
					}
				}
			}

			&.radio_inline {
				margin-bottom: 30px;

				.form-radios {
					@include clearfix();

					.form-item {
						float:left;

					 	&:not(:last-child) {
					 		margin-right: 40px;

							@include breakpoint(small down) {
								margin-right: 20px;
							}
					 	}
					}
				}
			}

			&.label_inline {

				> label,
				> input {
					display: inline-block;
					vertical-align: middle;
					width: auto;
				}

				> label {
					margin-right: 30px;
					color: $guessWhatItsAnotherGreyText;
					font-size: 14px;

					@include breakpoint(large down) {
						margin-right: 20px;
					}

					@include breakpoint(small down) {
						display: block;
						margin-bottom: 10px;
					}
				}

				> input {
					width: 170px;

					@include breakpoint(1300px down) {
						width: 150px;
					}

					@include breakpoint(large down) {
						width: 110px;
					}

					@include breakpoint(small down) {
						display: block;
						width: 100%;
					}
				}
			}

			&.webform-component-markup {
				color: $blue;
				font-size: 16px;
				padding-top: 15px;
				padding-bottom: 15px;
				line-height: 25px;

				@include breakpoint(small down) {
					font-size: 12px;
					line-height: 20px;
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			&.line_height {
				line-height: 40px;
			}

			&.m2 {
				position: relative;

				input {
					padding-right: 60px;
				}

				.field-suffix {
					position: absolute;
					right: 11px;
					top: 1px;
					bottom: 1px;
					background: $lightGreyBg;
					width: 60px;
					border-left: 1px solid $greyWebformBorder;
					font-size: 14px;
					color: $blue;
					text-align: center;
					line-height: 48px;
					border-radius: 0 5px 5px 0;

					@include breakpoint(small down) {
						line-height: 38px;
					}
				}
			}

			&.photos {
			
				@include breakpoint(medium down) {
					width: 100%;
				}

				label {
					display: inline-block !important;
					@include goldBorderButton();
					cursor: pointer;
					padding: 14px 35px
				}

				input[type=file] {
					display: none;
				}

				.MultiFile-list {
					margin-top: 10px;

					.MultiFile-label {
						height: 32px;
						line-height: 32px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-left: 40px;
						position: relative;

						@include breakpoint(small down) {
							width: calc(100vw - 20px);
						}

						.MultiFile-remove {
							position: absolute;
							left: 0;
							top: 0;
							display: inline-block;
							width: 32px;
							height: 32px;
							background: url(../img/icon_annuler_hover.png) center no-repeat;
							font-size: 0;
						}

						.MultiFile-title {
							display: inline-block;
							font-size: 14px;

							@include breakpoint(small down) {
								width: 12px;
							}
						}
					}
				}
			}

			&.label > label {
				display: block;
				font-size: 14px;
				margin-bottom: 15px;
				margin-top: 15px;
			}

			&.clear {
				clear: left;
			}
		}

		.captcha {

			@include breakpoint(large up) {
				padding-left: 100px;
				float: left;
			}

			@include breakpoint(small down) {
				margin-bottom: 20px;
				text-align: center;

				.g-recaptcha {
					display: inline-block;
				}
			}
		}

		.form-actions {
			text-align: right;
			float: right;

			@include breakpoint(large up) {
				padding-right: 100px;
			}

			input[type=submit] {
				@include goldButton();
				@include goldGradient();
				padding: 19px 35px;
				height: auto;
				width: auto;
				border: none;
				border: 1px solid $gold;

				&:hover {
					background: $white;
				}
			}
		}
	}

	&#user-login {
		padding: 60px 15px;
		max-width: 500px;
		width: 100%;
		margin: auto;

		label {
			display: block;
		 	margin-bottom: 8px;
		 	font-size: 16px;
		}

		.description {
		 	display: none;
		}

		input:not([type=submit]) {
			border: 1px solid $black;
			margin-bottom: 30px;
			height: 50px;
			font-size: 14px;
			font-weight: 700;
			color: $blue;
			padding: 0 15px;
			width: 100%;

	   		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  opacity: 0;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  opacity: 0;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  opacity: 0;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  opacity: 0;
			}
		}

		.form-actions {
			text-align: right;

			input[type=submit] {
				@include goldButton();
				@include goldGradient();
				padding: 14px 35px;
				height: auto;
				width: auto;
				border: none;
				border: 1px solid $gold;

				&:hover {
					background: $white;
				}
			}
		}
	}
}
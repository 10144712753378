section#landing_page,
section#page_base {
	padding-bottom: 60px;

	#breadcrumb {
		padding: 20px 0;
	}

	h1 {
		@include homeTitle($blue);
		margin-bottom: 60px;
	}
}
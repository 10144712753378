.social_menu {
	font-size: 0;
	text-align: center;

	li {
		display: inline-block;
		vertical-align: middle;

		&:not(:last-child) {
			margin-right: 10px;
		}

		a {
			display: block;
			width: 48px;
			height: 48px;
			line-height: 48px;
			text-align: center;

			&:before {
				font-size: 20px;
				@include transition(0.3s);
			}
		}
	}

	&.grey li a {
		background: $white;

		&:before {
			color: $darkGreyBg;
		}

		&:hover:before {
			color: $blue;
		}
	}

	&.blue li a {
		background: $otherBlue;

		&:before {
			color: $blue;
		}

		&:hover {
			background: $white;

			&:before {
				color: $gold;
			}
		}
	}
}
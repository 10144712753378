.pagination {
	text-align: center;
	@include clearfix();
	margin-top: 40px;
	padding-bottom: 25px;

	li {
		display: inline-block;
		vertical-align: top;

		a {
			display: block;
			height: 40px;
			width: 30px;
			line-height: 40px;
			color: $gold;
			font-size: 14px;

			&:hover {
				color: $blue;
			}
		}

		&.first,
		&.last {
			display: none;
		}

		&.prev {
			float: left;
		}

		&.next {
			float: right;
		}

		&.prev,
		&.next {

			a {
				width: auto;
				text-transform: uppercase;
			}

			@include breakpoint(small down) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;
			opacity: 0;
		}

		&.active a {
			position: relative;
			color: $white;
			z-index: 1;
			pointer-events: 0;
			width: 40px;
			cursor: default;

			&:before {
				content: "";
				@include goldGradient();
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				z-index: -1;
				border-radius: 5px;
			}
		}
	}
}
#back_to_top {
	position: fixed;
	right: 40px;
	bottom: 40px;
	display: inline-block;
	width: 50px;
	height: 50px;
	z-index: 9;
	border: 1px solid $gold;
	background: url(../img/arrow_up_gold.png) center no-repeat $white;
	background-size: 26px auto;
	border-radius: 5px;
	@include transition(0.3s);

	&:after,
	&:before {
		content: "";
		position: absolute;
		top: -1px;
		left: -1px;
		bottom: -1px;
		right: -1px;
		z-index: 1;
		@include transition(0.3s);
	}

	&:before {		
		border-radius: 5px;
		@include goldGradient();
	}

	&:after {
		background: url(../img/arrow_up_white.png) center no-repeat;
		background-size: 26px auto;
	}

	&:hover {

		&:before,
		&:after {
			opacity: 0;
		}
	}

	&:not(.visible) {
		opacity: 0;
	}

	@include breakpoint(small down) {
		display: none;
	}
}
html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
  @include box-sizing(border-box);
}

*:before,
*:after {
  @include box-sizing(border-box);
}

a,
input {
	@include transition(0.3s);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

body{
	margin: 0 !important;
	color: $black; 
	font-family: $ffP;
	background: $bodyBG;

	&.no-scroll{
		overflow: hidden;
	}	
}

.element-invisible,
.flag-throbber {
	display: none; 
}

a, input{
	outline: none; 
}
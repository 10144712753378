#breadcrumb {
	font-size: 12px;
	text-align: right;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.5px;

	&.white {
		color: $white;

		@include breakpoint(medium down) {
			margin-bottom: 25px;
		}

		a {
			color: $white;

			&:hover {
				color: $gold;
			}
		}
	}

	&:not(.white) {
		color: $otherGreyText;

		a {
			color: $otherGreyText;

			&:hover {
				color: $blue;
			}
		}
	}
}